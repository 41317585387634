import type { onStatelessParameters } from "@hocuspocus/provider";
import {
  type Params,
  createAsync,
  query,
  useLocation,
  useParams,
} from "@solidjs/router";
import type { Editor as TiptapEditor } from "@tiptap/core";
import type { Node } from "@tiptap/pm/model";
import { toString as convertToString } from "hast-util-to-string";
import {
  For,
  Match,
  Show,
  Switch,
  batch,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
  untrack,
} from "solid-js";
import { visit } from "unist-util-visit";
import { gql } from "~/__gql-generated__";
import {
  type InfoQuery,
  PostStatus,
  PostType,
  UserRole,
} from "~/__gql-generated__/graphql";

import { Meta } from "@solidjs/meta";
import { isServer } from "solid-js/web";
import Description from "~/components/Description";
import styles from "~/components/Post.module.scss";
import navStyles from "~/components/PostNav.module.scss";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import Title from "~/components/Title";
import type { Provider } from "~/components/post-editing/build-collab";
import NotFound from "~/routes/[...404]";
import {
  calculateReadingTime,
  wordSegmenter,
} from "~/utils/calculateReadingTime";
import { clientLazy } from "~/utils/clientLazy";
import { fragmentToJsx } from "~/utils/fragmentToJsx";
import {
  generateRetinaThumbs,
  generateWidthThumbs,
} from "~/utils/generateThumbs";
import { client } from "~/utils/graphql";
import { renderFragment } from "~/utils/renderFragment";
import { resolvedColorScheme } from "~/utils/resolvedColorScheme";
import { toYearMonthPair } from "~/utils/toYearMonthPair";

const Checkbox = clientLazy(() => import("~/components/post-editing/Checkbox"));
const DatePicker = clientLazy(
  () => import("~/components/post-editing/DatePicker"),
);
const Editor = clientLazy(() => import("~/components/post-editing/Editor"));
const Image = clientLazy(() => import("~/components/post-editing/Image"));
const Select = clientLazy(() => import("~/components/post-editing/Select"));
const Tags = clientLazy(() => import("~/components/post-editing/Tags"));
const PostNav = clientLazy(() => import("~/components/PostNav"));
const ProseMirrorTextToJsx = clientLazy(
  () => import("~/components/ProseMirrorTextToJsx"),
);

const Comments = clientLazy(() => import("~/components/Comments"));

interface CollectedPostHeading {
  level: string;
  id: string;
  node: Node;
  schema: TiptapEditor["schema"];
}

function toOptionRecord<T extends Record<string, any>>(
  array: T[],
  key: keyof T,
  value: keyof T,
) {
  const result: Record<string, string> = {};

  for (const item of array) {
    result[item[key]] = item[value]?.toString() ?? "";
  }

  return result;
}

const POST_LIGHT = gql(`
  query PostLight($id: ID!) {
    posts(where: { id: $id }) {
      hasPendingModifications
      deletedAt
      status
      author {
        id
      }
      category {
        id
      }
    }
  }
`);

const INFO = gql(`
  query Info($userId: ID!, $categoryId: ID) {
    me {
      id
      roles
    }
    users(
      where: {
        OR: [
          {
            OR: [
              { roles_INCLUDES: SUPER }
              { roles_INCLUDES: ADMIN }
              { roles_INCLUDES: AUTHOR_ADMIN }
              { roles_INCLUDES: AUTHOR }
            ]
            deletedAt: null
          }
          { id: $userId }
        ]
      }
      options: { sort: { displayName: ASC } }
    ) {
      id
      displayName
      profile {
        slug
        gender
        picture {
          url
        }
      }
    }
    categories(
      where: { OR: [{ deletedAt: null }, { id: $categoryId }] }
      options: { sort: { title: ASC } }
    ) {
      id
      title
      cover {
        url
      }
    }
  }
`);

const POST = gql(`
  query Post($slug: String!, $type: PostType!, $id: ID!) {
    posts(
      where: {
        OR: [{ slug: $slug, status: PUBLISHED, type: $type }, { id: $id }]
      }
    ) {
      id
      oldId
      slug
      type
      publishedAt
      updatedAt
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            lead
            body
            author {
              id
              displayName
              profile {
                slug
                gender
                blurb
                picture {
                  url
                }
              }
            }
            category {
              id
              slug
              title
              cover {
                url
              }
            }
            cover {
              url
            }
            tags {
              id
              slug
              title
            }
          }
        }
      }
    }
  }
`);

export const getFullPost = query(
  async (parameters: Params, type: PostType = PostType.Article) => {
    "use server";

    if (!parameters.slug && !parameters.id) {
      return "Post not found (empty slug)";
    }

    const result = await client.query({
      query: POST,
      variables: {
        slug: parameters.slug || "",
        id: parameters.id || "",
        type,
      },
    });
    if (result.data.posts.length === 0) {
      return "Post not found (id/slug mismatch)";
    }

    const post = result.data.posts[0];

    if (post.revisionsConnection.edges.length === 0 && parameters.id) {
      return {};
    }

    const revision = post.revisionsConnection.edges[0]?.node;

    const publishedAt = new Date(post.publishedAt);
    const updatedAt = new Date(post.updatedAt);

    if (!parameters.id && type === PostType.Article) {
      const year = Number.parseInt(parameters.year, 10);
      const month = Number.parseInt(parameters.month, 10);

      if (
        revision.category?.slug !== parameters.category ||
        publishedAt.getFullYear() !== year ||
        publishedAt.getMonth() + 1 !== month
      ) {
        return "Post not found (parameters mismatch)";
      }
    }

    const body = await renderFragment(
      JSON.parse(revision.body ?? "{}"),
      false,
      post.type === PostType.Article ? post.id : undefined,
    );

    const wordCount = [...wordSegmenter.segment(convertToString(body))].filter(
      (segment) => segment.isWordLike,
    ).length;

    let imageCount = 0;
    visit(body, "element", (node) => {
      if (node.tagName === "img") {
        imageCount++;
      }
    });

    return {
      id: post.id,
      oldId: post.oldId,
      slug: post.slug,
      type: post.type,
      title: await renderFragment(JSON.parse(revision.title ?? "{}"), true),
      lead: await renderFragment(JSON.parse(revision.lead ?? "{}"), true),
      body,
      publishedAt,
      updatedAt,
      author: revision.author,
      authorPictureSet: generateRetinaThumbs(
        revision.author.profile?.picture.url ?? "",
        "64x64",
      ),
      authorLargePictureSet: generateRetinaThumbs(
        revision.author.profile?.picture.url ?? "",
        "160x160",
      ),
      category: revision.category,
      cover: revision.cover,
      coverSet: generateWidthThumbs(revision.cover?.url ?? "", 16 / 9),
      tags: revision.tags,
      readingTime: calculateReadingTime(wordCount, imageCount),
    };
  },
  "post",
);

interface PostProps {
  readonly editMode?: boolean;
  readonly type?: PostType;
}

export default function Post(originalProps: PostProps) {
  const props = mergeProps({ type: PostType.Article }, originalProps);

  const [provider, setProvider] = createSignal<Provider | null>(null);
  const [userList, setUserList] = createSignal<Record<string, string>>({});
  const [extendedUserList, setExtendedUserList] = createSignal<
    InfoQuery["users"]
  >([]);
  const [categoryList, setCategoryList] = createSignal<Record<string, string>>(
    {},
  );

  createEffect(() => {
    async function resolveProvider() {
      const {
        data: { posts },
      } = await client.query({
        query: POST_LIGHT,
        variables: { id: parameters.id },
      });

      if (posts.length === 0) {
        setError("Post not found (unable to fetch)");
        return;
      }

      const [post] = posts;

      const {
        data: { me, users, categories },
      } = await client.query({
        query: INFO,
        variables: {
          userId: post.author.id,
          categoryId: post.category?.id,
        },
      });

      if (!me.id) {
        setError("Post not found (unauthorized)");
        return;
      }

      const thisUser = users.find((user) => user.id === me.id);
      setRoles(me.roles);
      const { buildProvider } = await import(
        "~/components/post-editing/build-collab"
      );

      batch(() => {
        setUserList(toOptionRecord(users, "id", "displayName"));
        setExtendedUserList(users);
        setCategoryList(toOptionRecord(categories, "id", "title"));
        setIsModified(post.hasPendingModifications);
        setIsPublished(post.status !== PostStatus.Draft);
        setIsDeleted(Boolean(post.deletedAt));
        setProvider((provider) => {
          provider?.original.destroy();
          return buildProvider(parameters.id, {
            name: thisUser?.displayName,
            id: thisUser?.id,
            avatar: thisUser?.profile?.picture.url,
          });
        });
      });

      untrack(provider)
        ?.original.on("stateless", handleStateless)
        .on("connect", handleConnect)
        .on("disconnect", handleDisconnect)
        .on("synced", handleSynced);
    }

    if (!props.editMode || !parameters.id) {
      return;
    }

    void resolveProvider();

    onCleanup(() => {
      untrack(provider)
        ?.original.off("stateless", handleStateless)
        .off("connect", handleConnect)
        .off("disconnect", handleDisconnect)
        .off("synced", handleSynced);
    });
  });

  const parameters = useParams();
  const [error, setError] = createSignal("");
  const post = createAsync(
    async () => {
      if (!parameters.slug && !parameters.id) {
        setError("Post not found (slug not provided)");
        return;
      }

      const result = await getFullPost({ ...parameters }, props.type);
      if (typeof result === "string") {
        setError(result);
        return;
      }

      return result;
    },
    { deferStream: true },
  );

  let isConnected = false;
  const [isSaving, setIsSaving] = createSignal(true);
  const [snackMessage, setSnackMessage] = createSignal("");
  const [isSynced, setIsSynced] = createSignal(false);

  let snackMessageTimeout: ReturnType<typeof setTimeout>;
  const changeSnackMessage = (message: string) => {
    clearTimeout(snackMessageTimeout);
    setSnackMessage(message);
    snackMessageTimeout = setTimeout(() => {
      setSnackMessage("");
    }, 10_000);
  };

  const handleStateless = ({ payload }: onStatelessParameters) => {
    if (!provider()) {
      return;
    }

    const json = JSON.parse(payload);

    switch (json.type) {
      case "updatingPost": {
        changeSnackMessage("Salvando...");
        setIsSaving(true);
        break;
      }

      case "sheddingRevision": {
        batch(() => {
          changeSnackMessage(
            json.detail.status === "PUBLISHED"
              ? "Publicando artigo..."
              : json.detail.status === "DRAFT"
                ? "Despublicando artigo..."
                : "Criando revisão...",
          );
          setIsSaving(true);
        });
        break;
      }

      case "discardingRevision": {
        batch(() => {
          changeSnackMessage("Revertendo modificações...");
          setIsSaving(true);
        });
        break;
      }

      case "postUpdated": {
        batch(() => {
          changeSnackMessage("Alterações salvas automaticamente");
          setIsModified(true);
          setIsSaving(false);
        });
        break;
      }

      case "revisionShed": {
        batch(() => {
          changeSnackMessage(
            json.detail.status === "PUBLISHED"
              ? "Artigo publicado"
              : json.detail.status === "DRAFT"
                ? "Artigo despublicado"
                : "Revisão criada",
          );
          setIsModified(false);
          setIsSaving(false);

          if (json.detail.status) {
            setIsPublished(json.detail.status === "PUBLISHED");
          }
        });
        break;
      }

      case "revisionDiscarded": {
        batch(() => {
          changeSnackMessage("Modificações revertidas");
          setIsModified(false);
          setIsSaving(false);
        });
        break;
      }

      case "trash": {
        changeSnackMessage("Apagando...");
        break;
      }

      case "trashed": {
        changeSnackMessage("Artigo no lixo; voltando à listagem...");
        setIsDeleted(true);
        globalThis.location.href = json.detail;
        break;
      }

      case "restore": {
        changeSnackMessage("Restaurando...");
        break;
      }

      case "restored": {
        changeSnackMessage("Artigo restaurado do lixo");
        setIsDeleted(false);
        break;
      }

      case "updateError":
      case "shedError":
      case "discardError":
      case "trashError":
      case "restoreError": {
        batch(() => {
          changeSnackMessage("Erro! Talvez avise o 「AMF」...");
          setIsSaving(false);
        });
        break;
      }

      default: // Do nothing
    }
  };

  const handleChange = () => {
    if (!provider() || !isSynced()) {
      return;
    }

    provider()?.original.sendStateless(JSON.stringify({ type: "type" }));
  };

  const handleConnect = () => {
    if (!provider()) {
      return;
    }

    isConnected = true;
  };

  const handleDisconnect = () => {
    if (!provider()) {
      return;
    }

    isConnected = false;
    batch(() => {
      setIsSaving(true);
      setIsSynced(false);
    });
  };

  const handleSynced = () => {
    if (!provider() || !isConnected) {
      return;
    }

    batch(() => {
      setIsSaving(false);
      setIsSynced(true);
    });
  };

  const shedRevision = () => {
    provider()?.original.sendStateless(
      JSON.stringify({ type: "shed", detail: {} }),
    );
  };

  const shedAndPublish = () => {
    provider()?.original.sendStateless(
      JSON.stringify({ type: "shed", detail: { status: "PUBLISHED" } }),
    );
  };

  const unpublishPost = () => {
    provider()?.original.sendStateless(
      JSON.stringify({ type: "shed", detail: { status: "DRAFT" } }),
    );
  };

  const discardRevision = () => {
    if (
      !confirm(
        "Tem certeza de que deseja descartar as modificações?\n\nESTA AÇÃO NÃO PODERÁ SER DESFEITA.",
      )
    ) {
      return;
    }

    provider()?.original.sendStateless(JSON.stringify({ type: "discard" }));
  };

  const trashPost = () => {
    if (!confirm("Tem certeza de que deseja mandar o artigo para o lixo?")) {
      return;
    }

    provider()?.original.sendStateless(JSON.stringify({ type: "trash" }));
  };

  const restorePost = () => {
    provider()?.original.sendStateless(JSON.stringify({ type: "restore" }));
  };

  const [user, setUser] = createSignal("");
  const handleUserChange = (value: string) => {
    setUser(value);
    handleChange();
  };

  const [readingTime, setReadingTime] = createSignal(-1);
  const handleBodySync = (editor: TiptapEditor) => {
    const wordCount = (editor.storage.characterCount?.words() ?? -1) as number;
    let readingTime = -1;

    if (wordCount > -1) {
      const imageCount = editor.getHTML().match(/<img\s/g)?.length ?? 0;
      readingTime = calculateReadingTime(wordCount, imageCount);
    }

    setReadingTime(readingTime);

    const headings: CollectedPostHeading[] = [];

    for (const node of editor.state.doc.children) {
      if (node.type.name !== "heading" || node.attrs.level > 3) {
        continue;
      }

      headings.push({
        level: navStyles[`level-${node.attrs.level as string}`],
        id: node.attrs.id,
        node,
        schema: editor.schema,
      });
    }

    setHeadings(headings);
  };
  createEffect(() => {
    setReadingTime(post()?.readingTime ?? -1);
  });

  const readingTimeFormatted = () => {
    const minutes = readingTime();
    return `${minutes < 1 ? "menos de " : ""}${Math.max(1, minutes).toLocaleString(import.meta.env.VITE_LOCALE)} ${minutes >= 2 ? "minutos" : "minuto"}`;
  };

  const location = useLocation();
  const url = () =>
    `${import.meta.env.VITE_SITE_HOST}${location.pathname}`.replace(/\/+$/, "");
  const shareTitle = () => fragmentToJsx(post()?.title, true) as string;
  const shareLead = () => fragmentToJsx(post()?.lead, true) as string;

  const nativeShare = async () => {
    try {
      await navigator.share({
        url: url(),
        title: shareTitle(),
        text: shareLead(),
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const colorScheme = resolvedColorScheme();

  const [isDraggingOver, setIsDraggingOver] = createSignal(false);
  let draggingOverRef: HTMLElement;

  const [headings, setHeadings] = createSignal<CollectedPostHeading[]>([]);

  const [isModified, setIsModified] = createSignal(false);

  const [isLocked, setIsLocked] = createSignal(false);
  const handleTitleSync = (editor: TiptapEditor) => {
    setIsLocked(
      /^\s*(?:WIP|RASCUNHO|DRAFT)(?:\s+|$)/i.test(
        editor.getText().replaceAll(/\W/g, " "),
      ),
    );
  };

  const [isDeleted, setIsDeleted] = createSignal(false);
  const [isPublished, setIsPublished] = createSignal(false);
  const [roles, setRoles] = createSignal<UserRole[]>([]);

  const canPublish = () =>
    roles().some((role) =>
      [UserRole.Super, UserRole.Admin, UserRole.AuthorAdmin].includes(role),
    );

  const postType = () =>
    post()?.type === PostType.Article ? "Artigo" : "Página";

  const disqusId = () => post()?.oldId?.toString() ?? post()?.id ?? "";
  const disqusUrl = () =>
    `${import.meta.env.VITE_SITE_HOST}/${post()?.category?.slug}/${toYearMonthPair(post()?.publishedAt ?? new Date())}/${post()?.slug}`;

  return (
    <Switch fallback={<NotFound />}>
      <Match when={error().startsWith("Post not found")}>
        <NotFound />
      </Match>
      <Match when={!error()}>
        <Title>
          {(props.editMode ? `Editando ${postType().toLowerCase()}: ` : "") +
            shareTitle()}
        </Title>
        <Description>{fragmentToJsx(post()?.lead, true) as string}</Description>
        <Show when={post()?.cover}>
          <Meta property="og:image" content={post()?.cover?.url} />
        </Show>
        <Meta
          property="og:updated_time"
          content={post()?.updatedAt?.toISOString()}
        />
        <Show when={props.type === PostType.Article}>
          <Meta property="og:type" content="article" />
          <For each={post()?.tags}>
            {(tag) => <Meta property="article:tag" content={tag.title} />}
          </For>
          <Meta
            property="article:publisher"
            content="https://www.facebook.com/radiojhero"
          />
          <Meta
            property="article:published_time"
            content={post()?.publishedAt?.toISOString()}
          />
          <Meta
            property="article:modified_time"
            content={post()?.updatedAt?.toISOString()}
          />
          <Meta property="article:section" content={post()?.category?.title} />
          <Meta name="twitter:title" content={shareTitle()} />
          <Meta name="twitter:label1" content="Escrito por" />
          <Meta name="twitter:data1" content={post()?.author?.displayName} />
          <Meta name="twitter:label2" content="Tempo de leitura" />
          <Meta name="twitter:data2" content={readingTimeFormatted()} />
        </Show>
        <main>
          <Show when={provider() !== null}>
            <div class={styles.snackbar}>
              {isSynced() ? snackMessage() : "Sincronizando..."}
            </div>
          </Show>
          <article
            classList={{
              [rootStyles["styled-links"]]: true,
              [`post-is-${post()?.type?.toLowerCase()}`]: true,
            }}
          >
            <header
              classList={{
                [styles.hero]: true,
                [styles["drag-over"]]: isDraggingOver(),
              }}
              onDragEnter={(event) => {
                if (provider() === null) {
                  return;
                }

                draggingOverRef = event.target as HTMLElement;
                setIsDraggingOver(true);
              }}
              onDragLeave={(event) => {
                if (provider() === null) {
                  return;
                }

                if (
                  event.target === draggingOverRef &&
                  event.target.matches('input[type="file"]')
                ) {
                  setIsDraggingOver(false);
                }
              }}
              onDrop={() => {
                if (provider() === null) {
                  return;
                }

                setIsDraggingOver(false);
              }}
            >
              <Show
                when={provider() !== null}
                fallback={
                  <img
                    class={styles["hero-image"]}
                    src={post()?.cover?.url}
                    srcSet={post()?.coverSet}
                    sizes="auto"
                    alt=""
                  />
                }
              >
                <label class={rootStyles["sr-only"]} for="cover">
                  Imagem de capa:
                </label>
                <Image
                  classList={{
                    [styles["image-input"]]: true,
                    [styles["drag-over"]]: isDraggingOver(),
                  }}
                  collaborationProvider={provider()?.original}
                  collaborationField="cover"
                  onChange={handleChange}
                />
              </Show>
              <Show when={props.type === PostType.Article}>
                <div class={styles.category}>
                  <Show
                    when={provider() !== null}
                    fallback={
                      <SmartA
                        class={rootStyles.pseudo}
                        href={`/${post()?.category?.slug ?? ""}`}
                      >
                        <span class={rootStyles["sr-only"]}>Coluna: </span>
                        {post()?.category?.title}
                      </SmartA>
                    }
                  >
                    <label class={rootStyles["sr-only"]} for="category">
                      Coluna:
                    </label>
                    <Select
                      collaborationProvider={provider()?.original}
                      collaborationField="category"
                      options={categoryList()}
                      placeholder="Selecione coluna..."
                      onChange={handleChange}
                    />
                  </Show>
                </div>
              </Show>
              <h1>
                <Show
                  when={provider() !== null}
                  fallback={fragmentToJsx(post()?.title)}
                >
                  <Editor
                    inline
                    collaborationProvider={provider()}
                    collaborationField="title"
                    placeholder="Insira título..."
                    onSync={handleTitleSync}
                    onChange={handleChange}
                  />
                </Show>
              </h1>
              <p>
                <Show
                  when={provider() !== null}
                  fallback={fragmentToJsx(post()?.lead)}
                >
                  <Editor
                    inline
                    collaborationProvider={provider()}
                    collaborationField="lead"
                    placeholder="Insira subtítulo..."
                    onChange={handleChange}
                  />
                </Show>
              </p>
              <Show when={props.type === PostType.Article}>
                <div class={styles.meta}>
                  <Show
                    when={provider() !== null}
                    fallback={
                      <>
                        <div class={styles["author-image"]}>
                          <img
                            src={post()?.author?.profile?.picture.url}
                            srcSet={post()?.authorPictureSet}
                            alt=""
                          />
                        </div>
                        <div class={styles["extra-meta"]}>
                          <div>
                            <SmartA
                              classList={{
                                [rootStyles.pseudo]: true,
                                [styles["author-link"]]: true,
                              }}
                              href={`/${post()?.author?.profile?.slug ?? ""}`}
                            >
                              {post()?.author?.displayName}
                            </SmartA>{" "}
                            ·{" "}
                            {post()?.publishedAt?.toLocaleString(
                              import.meta.env.VITE_LOCALE,
                              {
                                dateStyle: "long",
                                timeStyle: "short",
                                timeZone: import.meta.env.VITE_TZ,
                              },
                            )}
                          </div>
                          <div title="Considerando 200 palavras por minuto e 12 segundos por imagem">
                            {readingTimeFormatted()} de leitura
                          </div>
                        </div>
                      </>
                    }
                  >
                    <div class={styles["author-image"]}>
                      <img
                        src={
                          extendedUserList().find((u) => u.id === user())
                            ?.profile?.picture.url ?? "about:blank"
                        }
                        alt=""
                        onLoad={(event) => {
                          event.currentTarget.classList.remove(styles.error);
                        }}
                        onError={(event) => {
                          event.currentTarget.classList.add(styles.error);
                        }}
                      />
                      <IconTablerUser />
                    </div>
                    <div class={styles["extra-meta"]}>
                      <div class={styles.controls}>
                        <label class={rootStyles["sr-only"]} for="author">
                          Autor(a):
                        </label>
                        <Select
                          collaborationProvider={provider()?.original}
                          collaborationField="author"
                          options={userList()}
                          placeholder="Selecione autor(a)..."
                          onChange={handleUserChange}
                          onRemoteChange={setUser}
                        />
                        {" · "}
                        <label class={rootStyles["sr-only"]} for="publishedAt">
                          Data de publicação:
                        </label>
                        <DatePicker
                          collaborationProvider={provider()?.original}
                          collaborationField="publishedAt"
                          dateFormat={{
                            dateStyle: "long",
                            timeStyle: "short",
                            timeZone: import.meta.env.VITE_TZ,
                          }}
                          placeholder="Publicação imediata"
                          onChange={handleChange}
                        />
                      </div>
                      <div title="Considerando 200 palavras por minuto e 12 segundos por imagem">
                        {readingTimeFormatted()} de leitura
                      </div>
                    </div>
                  </Show>
                </div>
              </Show>
            </header>
            <div class={styles.body}>
              <Show when={provider() !== null && headings().length > 0}>
                <PostNav class={navStyles.toc}>
                  <h2>Índice</h2>
                  <ul>
                    <For each={headings()}>
                      {(heading) => (
                        <li class={heading.level}>
                          <a href={`#${heading.id}`}>
                            <ProseMirrorTextToJsx
                              node={heading.node}
                              schema={heading.schema}
                            />
                          </a>
                        </li>
                      )}
                    </For>
                  </ul>
                </PostNav>
              </Show>
              <Show
                when={provider() !== null}
                fallback={fragmentToJsx(post()?.body)}
              >
                <Editor
                  withLinks
                  collaborationProvider={provider()}
                  collaborationField="body"
                  placeholder="Insira conteúdo..."
                  onSync={handleBodySync}
                  onChange={handleChange}
                />
              </Show>
            </div>
            <Show when={props.type === PostType.Article}>
              <Show
                when={provider() !== null}
                fallback={
                  <Show when={(post()?.tags ?? []).length > 0}>
                    <div class={styles["tags-list"]}>
                      <ul>
                        <For each={post()?.tags ?? []}>
                          {(tag) => (
                            <li>
                              <SmartA href={`/tag/${tag.slug}`}>
                                <span class={styles.hashtag}>#</span>
                                {tag.title}
                              </SmartA>
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                  </Show>
                }
              >
                <Tags
                  collaborationProvider={provider()?.original}
                  collaborationField="tags"
                  onChange={handleChange}
                />
              </Show>
              <Show when={!props.editMode}>
                <section class={styles.share}>
                  <h2>Compartilhe</h2>
                  <ul>
                    <li>
                      <SmartA
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url())}&t=${encodeURIComponent(shareTitle())}`}
                      >
                        <img
                          loading="lazy"
                          src={`/assets/brands/facebook/${colorScheme()}.svg`}
                          alt="Compartilhar no Facebook"
                        />
                      </SmartA>
                    </li>
                    <li>
                      <SmartA
                        href={`https://x.com/intent/post?text=${encodeURIComponent(shareTitle())}&url=${encodeURIComponent(url())}&via=${encodeURIComponent(import.meta.env.VITE_USERNAME_TWITTER)}`}
                      >
                        <img
                          loading="lazy"
                          src={`/assets/brands/twitter/${colorScheme()}.svg`}
                          alt="Compartilhar no X"
                        />
                      </SmartA>
                    </li>
                    <li>
                      <SmartA
                        href={`whatsapp://send?text=${encodeURIComponent(url())}`}
                      >
                        <img
                          loading="lazy"
                          src={`/assets/brands/whatsapp/${colorScheme()}.svg`}
                          alt="Compartilhar no WhatsApp"
                        />
                      </SmartA>
                    </li>
                    <li>
                      <SmartA
                        href={`https://telegram.me/share/url?url=${encodeURIComponent(url())}&text=${encodeURIComponent(shareTitle())}`}
                      >
                        <img
                          loading="lazy"
                          src={`/assets/brands/telegram/${colorScheme()}.svg`}
                          alt="Compartilhar no Telegram"
                        />
                      </SmartA>
                    </li>
                    <Show when={!isServer && !!navigator.share}>
                      <li>
                        <button
                          type="button"
                          class={rootStyles.link}
                          onClick={() => void nativeShare()}
                        >
                          <IconTablerShare />
                          <span class={rootStyles["sr-only"]}>
                            Mais Opções...
                          </span>
                        </button>
                      </li>
                    </Show>
                  </ul>
                </section>
                <section class={styles.author}>
                  <img
                    src={post()?.author?.profile?.picture.url}
                    srcSet={post()?.authorLargePictureSet}
                    alt={`Foto de ${post()?.author?.displayName ?? ""}`}
                  />
                  <div>
                    <h2>
                      Sobre{" "}
                      <SmartA href={`/${post()?.author?.profile?.slug ?? ""}`}>
                        {post()?.author?.displayName}
                      </SmartA>
                    </h2>
                    <p>
                      {post()?.author?.profile?.blurb.trim() ||
                        "Aparentemente, esta pessoa quer manter uma aura de mistério quanto a si mesma."}
                    </p>
                  </div>
                </section>
                <Comments
                  identifier={disqusId()}
                  title={shareTitle()}
                  url={disqusUrl()}
                />
              </Show>
            </Show>
            <Show when={provider() !== null}>
              <div class={styles["bottom-controls"]}>
                <Show when={props.type === PostType.Article && canPublish()}>
                  <p class={styles["sticky-checkbox"]}>
                    <Checkbox
                      collaborationProvider={provider()?.original}
                      collaborationField="sticky"
                      onChange={handleChange}
                    />
                    <label for="sticky">Fixar no topo das listagens</label>
                  </p>
                </Show>
                <p>
                  <button
                    type="button"
                    disabled={isLocked() || isSaving() || !isModified()}
                    onClick={shedRevision}
                  >
                    Salvar Revisão
                  </button>
                  <Show when={canPublish()}>
                    <Show
                      when={isPublished()}
                      fallback={
                        <button
                          class={styles.success}
                          type="button"
                          disabled={(isLocked() || isSaving()) && !isDeleted()}
                          onClick={shedAndPublish}
                        >
                          Publicar {postType()}
                        </button>
                      }
                    >
                      <button
                        class={styles.warning}
                        type="button"
                        disabled={isSaving() && !isDeleted()}
                        onClick={unpublishPost}
                      >
                        Despublicar {postType()}
                      </button>
                    </Show>
                  </Show>
                  <button
                    class={styles.warning}
                    type="button"
                    disabled={isSaving() || !isModified()}
                    onClick={discardRevision}
                  >
                    Descartar Revisão
                  </button>
                  <Show
                    when={isDeleted()}
                    fallback={
                      <button
                        class={styles.dangerous}
                        type="button"
                        disabled={isSaving()}
                        onClick={trashPost}
                      >
                        Apagar {postType()}
                      </button>
                    }
                  >
                    <button
                      class={styles.success}
                      type="button"
                      disabled={isSaving()}
                      onClick={restorePost}
                    >
                      Restaurar Artigo
                    </button>
                  </Show>
                </p>
                <Show when={isLocked()}>
                  <p class={styles.plain}>
                    {post()?.type === PostType.Article
                      ? "Este artigo está marcado"
                      : "Esta página está marcada"}{" "}
                    como <strong>rascunho</strong> pois seu <em>título</em>{" "}
                    começa com <code>WIP</code>, <code>Rascunho</code> ou{" "}
                    <code>Draft</code>.{" "}
                    <strong>
                      Não é possível publicar novas revisões enquanto ele
                      estiver nesta condição.
                    </strong>
                  </p>
                </Show>
              </div>
            </Show>
          </article>
        </main>
      </Match>
    </Switch>
  );
}
