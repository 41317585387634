export const wordSegmenter = new Intl.Segmenter(import.meta.env.VITE_LOCALE, {
  granularity: "word",
});

// 200 wpm + ~12 seconds per image
export function calculateReadingTime(wordCount: number, imageCount: number) {
  let wps = wordCount / 200 / 60;

  for (let index = 0; index < imageCount; index++) {
    wps += Math.max(3, 12 - index);
  }

  return Math.round(wps / 60);
}
