import { createSignal, onCleanup, onMount } from "solid-js";

export function resolvedColorScheme() {
  const [colorScheme, setColorScheme] = createSignal("light");

  const handleColorScheme = (event: Event) => {
    const { detail: colorScheme } = event as CustomEvent<string>;
    resolveColorScheme(colorScheme);
  };

  const resolveColorScheme = (colorScheme: string) => {
    setColorScheme(
      colorScheme === "system"
        ? matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
        : colorScheme,
    );
  };

  onMount(() => {
    globalThis.addEventListener("colorschemechange", handleColorScheme);
    setColorScheme(
      document.documentElement.classList.contains("dark") ? "dark" : "light",
    );

    onCleanup(() => {
      globalThis.removeEventListener("colorschemechange", handleColorScheme);
    });
  });

  return colorScheme;
}
